.Button {
  width: 300px;
  height: 50px;
  margin: 20px 0;
  border-radius: 6px;
  font-size: 24px;
  background: #222;
  color: white;
  font-weight: 400;
  box-shadow: inset 2px 2px 2px white;
}

.Button:hover {
  cursor: pointer;
  background: #444;
}

.Button:active {
  box-shadow: 2px 2px 2px white;
}

.Button:focus {
  outline: none;
}

.Button-copy {
  padding: 0 25px;
}
