.FloatingLabelInput {
  display: block;
  position: relative;
  height: 44px;
  margin: 10px;
}

.FloatingLabelInput label, .FloatingLabelInput > div {
  position: absolute;
  left: 0;
  top: 0;
  cursor: text;
  font-size: 75%;
  opacity: 1;
  -webkit-transition: all .2s;
          transition: all .2s;
}

.FloatingLabelInput select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.FloatingLabelInput input, .FloatingLabelInput select, .FloatingLabelInput textarea {
  font-size: inherit;
  padding-top: 1em;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid rgba(255, 255, 255, 0.7);
  background: black;
  position: absolute;
  left: 0;
  color: white;
  width: 100%;
}

.FloatingLabelInput input::-webkit-input-placeholder, .FloatingLabelInput select::-webkit-input-placeholder, .FloatingLabelInput textarea::-webkit-input-placeholder {
  opacity: 1;
  -webkit-transition: all .2s;
          transition: all .2s;
}

.FloatingLabelInput input::-moz-placeholder, .FloatingLabelInput select::-moz-placeholder, .FloatingLabelInput textarea::-moz-placeholder {
  opacity: 1;
  transition: all .2s;
}

.FloatingLabelInput input:-ms-input-placeholder, .FloatingLabelInput select:-ms-input-placeholder, .FloatingLabelInput textarea:-ms-input-placeholder {
  opacity: 1;
  transition: all .2s;
}

.FloatingLabelInput input::placeholder, .FloatingLabelInput select::placeholder, .FloatingLabelInput textarea::placeholder {
  opacity: 1;
  -webkit-transition: all .2s;
          transition: all .2s;
}

.FloatingLabelInput input:placeholder-shown:not(:focus)::-webkit-input-placeholder, .FloatingLabelInput select:placeholder-shown:not(:focus)::-webkit-input-placeholder, .FloatingLabelInput textarea:placeholder-shown:not(:focus)::-webkit-input-placeholder {
  opacity: 0;
}

.FloatingLabelInput input:placeholder-shown:not(:focus)::-moz-placeholder, .FloatingLabelInput select:placeholder-shown:not(:focus)::-moz-placeholder, .FloatingLabelInput textarea:placeholder-shown:not(:focus)::-moz-placeholder {
  opacity: 0;
}

.FloatingLabelInput input:placeholder-shown:not(:focus):-ms-input-placeholder, .FloatingLabelInput select:placeholder-shown:not(:focus):-ms-input-placeholder, .FloatingLabelInput textarea:placeholder-shown:not(:focus):-ms-input-placeholder {
  opacity: 0;
}

.FloatingLabelInput input:placeholder-shown:not(:focus)::placeholder, .FloatingLabelInput select:placeholder-shown:not(:focus)::placeholder, .FloatingLabelInput textarea:placeholder-shown:not(:focus)::placeholder {
  opacity: 0;
}

.FloatingLabelInput input:placeholder-shown:not(:focus) + *, .FloatingLabelInput select:placeholder-shown:not(:focus) + *, .FloatingLabelInput textarea:placeholder-shown:not(:focus) + * {
  font-size: 150%;
  opacity: .5;
  top: .25em;
}

.FloatingLabelInput input:focus, .FloatingLabelInput select:focus, .FloatingLabelInput textarea:focus {
  outline: none;
  border-color: rgba(255, 255, 255, 0.5);
}

.FloatingLabelInput select {
  padding-right: 1em;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.5em bottom 0.25em;
  background-size: 8px 10px;
}
