.heading {
  margin-bottom: 2px;
  text-align: center;
}

.envelope {
  width: 160px;
  height: 88px;
  background-image: url('../images/envelope.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-color: bisque;
  margin: 0 auto;
}

.baby-seal {
  width: 70px;
  height: 70px;
  background-image: url('../images/baby-seal-thick.svg');
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0 auto;
  position: relative;
  top: 8px;
}

.slogan {
  margin-top: 6px;
  font-weight: 300;
}

.callout {
  font-weight: 500;
}

.step-container {
  border-top: 2px solid #666;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  margin-top: 30px;
}

.step-number {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 50%;
  font-weight: bold;
  box-shadow: 0 4px 4px white;
  flex-grow: 0;
  flex-shrink: 0;
}

.step-text {
  text-align: left;
  margin-left: 20px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  margin: 20px 0 0 0;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.email-password {
  margin-top: 0;
}

video {
  width: 100%;
  margin-top: 30px;
}

a {
  color: #97c6ff;
}

.Home {
  padding: 0 30px;
}

.Home .FloatingLabelInput {
  margin: 10px 0 0 21px;
}

.Home .email-username, .email-password {
  width: 200px;
}

.Home .email-password {
  margin: 10px 0 20px 21px;
}

.Home .action-copy {
  padding: 15px;
  text-align: justify;
}

@media only screen and (min-width: 700px) {
  .flex-row {
    margin: 20px 0 10px 0;
  }

  .step-container {
    padding: 0 30px;
  }

  .Home .email-password {
    margin-top: 30px;
  }

  .Home .step-text {
    margin-top: 30px;
  }
}
