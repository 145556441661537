.SealSpinner {
  width: 260px;
  height: 260px;
  background-image: url('../../seal-spinner.svg');
  background-size: cover;
  animation: spin 5s infinite linear;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    tranform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

