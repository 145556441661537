.NewMailserver .dns-instructions {
  width: 277px;
  margin: 0 auto;
  text-align: left;
}

.NewMailserver .dns-instructions .row {
  display: flex;
  flex-direction: column;
}

.NewMailserver .dns-instructions .values {
  width: 340px;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.NewMailserver .dns-instructions .instruction {
  width: 200px;
}

.NewMailserver .dns-instructions .row .column-name:not(:first-child) {
  margin-left: 30px;
}

.NewMailserver .dns-instructions .row .column-name {
  width: 169px;
  font-weight: bold;
  font-size: 18px;
}
