.CheckoutForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
}

.CheckoutForm .card-error {
  color: red;
}

.CheckoutForm .storage-options {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.CheckoutForm .storage-option {
  width: 300px;
  border: 2px solid white;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin: 20px auto 0;
  padding: 20px;
}

.CheckoutForm .storage-option .option {
  display: flex;
  justify-content: space-between;
}

.CheckoutForm .storage-option div {
  width: 100px;
  text-align: justify;
}

.CheckoutForm .storage-option span {
  margin-left: 10px;
}

.CheckoutForm .storage-option .price {
  width: 120px;
}

.CheckoutForm .billing-info {
  align-self: flex-start;
}

.CheckoutForm .credit-card-fields {
  width: 100%;
  margin-top: 10px;
}

.CheckoutForm .cc-info-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
}

.CheckoutForm .cc-cvc, .cc-exp-month, .cc-exp-year {
  width: 100%;
}

.CheckoutForm .cc-cvc, .cc-exp-year {
  margin-left: 90px;
}

.CheckoutForm .Button {
  width: 340px;
  margin-top: 40px;
}

@media only screen and (min-width: 700px) {
  .CheckoutForm .storage-option {
    align-items: center;
  }

  .CheckoutForm .storage-option .price {
    margin-left: 35px;
    width: 135px;
  }
}
